export const useDownloadFunctions = () => {
  const getFileName = (file, tv) => {
    if (file?.label) {
      if (file?.lookupKey.endsWith('.pdf') || file?.lookupKey.endsWith('.PDF') || file?.lookupKey.includes('.pdf?') || file?.lookupKey.includes('.PDF?')) {
        return file.label + ' (pdf)'
      }
      return file.label
    }
    if (file?.assetType) {
      let scopeValue = file.scopeValue ?? ''
      let typeTranslation = tv(file.assetType, 'product.assetLink') + ' ' + scopeValue
      if (file?.lookupKey.endsWith('.pdf') || file?.lookupKey.endsWith('.PDF') || file?.lookupKey.includes('.pdf?') || file?.lookupKey.includes('.PDF?')) {
        return typeTranslation + ' (pdf)'
      }
      return typeTranslation
    }
    if (file?.lookupKey) {
      let splitUrl = file.lookupKey.split('/')
      splitUrl = splitUrl[splitUrl.length - 1].split('?')
      return splitUrl[0]
    }
    return ''
  }

  const getNewLabel = (asset) => {
    let label = asset.label

    return label
  }

  const getDownloadLink = (file, url) => {
    if (file?.lookupKey.includes('http://') || file?.lookupKey.includes('https://')) {
      return file?.lookupKey ?? ''
    }
    return url + (file?.lookupKey ?? '')
  }

  const getAltText = (file) => {
    return file?.altText ?? ''
  }

  return {
    getFileName,
    getDownloadLink,
    getAltText,
    getNewLabel
  }
}
