import {useProductService} from '@/composables/product_service'
import {useConfigurationDataStore} from '@/store/configuration-data'
import {getMappedProduct} from '~/assets/js/utils/product_attributes_mapping'
import {useUserStore} from '@/store/user'
import {PROPERTY_SCOPE_NAMES} from '~/assets/js/constants'

export default {
  data() {
    return {
      reloadProductsInProgress: false
    }
  },
  methods: {
    reloadProduct(plantCode) {
      this.reloadProductsInProgress = true
      if (!this.product) {
        this.reloadProductsInProgress = false
        return
      }
      if (!useConfigurationDataStore().newSearch.value) {
        useProductService().getProductBySku(this.product?.sku, this.$globalization.getRegion(), this.$i18n.locale, useUserStore().showInchValues, plantCode, PROPERTY_SCOPE_NAMES.PRODUCT_PAGE).then((product) => {
          if (product) {
            this.product = getMappedProduct(product)
          }
        }).finally(() => {
          this.reloadProductsInProgress = false
        })
      } else {
        useProductService().getProductBySkuNew(this.product?.sku, this.$globalization.getRegion(), this.$i18n.locale, useUserStore().showInchValues, plantCode, PROPERTY_SCOPE_NAMES.PRODUCT_PAGE).then((product) => {
          if (product) {
            this.product = getMappedProduct(product)
          }
        }).finally(() => {
          this.reloadProductsInProgress = false
        })
      }
    }
  }
}
